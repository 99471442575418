<template>
  <v-dialog v-model="dialog" max-width="500px" :persistent="isIncomplete">
    <AppInvoice v-if="charge" :charge="charge" />
  </v-dialog>
</template>

<script>
import AppInvoice from "@/components/subscription/sections/AppInvoice.vue";

export default {
  components: {
    AppInvoice,
  },

  data() {
    return {
      dialog: false,
      charge: null,
    };
  },

  computed: {
    subscription() {
      return this.$store.state.subscription.subscription;
    },

    invoice() {
      return this.subscription.last_invoice;
    },

    hasSubscription() {
      return this.$store.getters["subscription/hasSubscription"];
    },
    isIncomplete() {
      return this.$store.getters["subscription/incomplete"];
    },
  },

  methods: {
    async open() {
      this.charge = null;
      this.$loading.start();
      await this.$http
        .index("subscription/efi/charge", {
          invoice_id: this.invoice.id,
          subscription_id: this.subscription.id,
        })
        .then((response) => {
          this.validateCharge(response.charge);
          this.$loading.finish();
          this.dialog = true;
          this.checkSubscription(this.subscription.id);
        })
        .catch((error) => this.$loading.finish());
    },

    async validateCharge(charge) {
      if (charge == null || charge.is_expired) {
        this.createCharge();
      } else {
        this.charge = charge;
      }
    },

    async createCharge() {
      this.$loading.start();
      await this.$http
        .store("subscription/efi/charge")
        .then((response) => {
          this.charge = response.charge;
          this.$loading.finish();
        })
        .catch((error) => this.$loading.finish());
    },

    async checkSubscription(subscription_id) {
      await this.$http
        .show("subscription/efi/checkout", subscription_id)
        .then((response) => {
          var isPaid = response.subscriptionIsPaid;

          if (isPaid) {
            this.$emit("update");
            this.dialog = false;
          }
          if (!isPaid) {
            setTimeout(() => this.checkSubscription(subscription_id), 5000);
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style>
</style>