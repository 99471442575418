<template>
  <div>
    <v-card
      height="100%"
      width="500px"
      id="invoice"
      :outlined="!$vuetify.breakpoint.mobile"
      flat
    >
      <v-card-title>
        <div class="text">
          Leia ou copie o QR Code Pix e pague utilizando o aplicativo do seu
          banco.
        </div>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-center qr_code_section pt-4">
          <div class="qr_code">
            <v-img width="200" :src="charge.pix_qr_code" />
          </div>
        </div>
        <div class="d-flex flex-column text-center">
          <span>Expira em</span>
          <b> {{ $format.dateBr(charge.due_date) }} </b>
        </div>
        <br />
        <div class="d-flex flex-column text-center">
          <div>
            <app-btn @click="handleCopyLink()" color="primary">
              <app-icon left>content_copy </app-icon>

              Copiar código
            </app-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    charge: {},
  },
  methods: {
    handleCopyLink() {
      navigator.clipboard
        .writeText(`${this.charge.pix_copia_e_cola} `)
        .then((response) => {
          this.$snackbar({
            message: "Código copiado com sucesso!",
            color: "success",
            timeout: 1000,
            showClose: false,
          });
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss">
#invoice {
  .v-card__title {
    background-color: #624de3;
    color: white;
    font-size: 16px;
    font-weight: 22px;
    align-items: center;
    justify-content: center;
    display: flex;
    .text {
      width: 70%;
      text-align: center;
    }
  }
  .v-card__text {
    .qr_code_section {
      // apenas a metade superior dev ter     background-color: #624de3;
    }
    .qr_code {
      border: 1px solid #624de3;
      padding: 10px;
      border-radius: 5px;
    }
  }
  // width: 100%;
  // height: 100%;
  // border: none;
}
</style>